import {
  Box, TextField, Button, Typography,
} from '@mui/material';
import React from 'react';
import Cookies from 'js-cookie';
import { style, renderSpinner, getAuthHeader } from './utils';

function Register(props) {
  const [user, setUser] = React.useState('');
  const [pass, setPass] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const [spinner, setSpinner] = React.useState(false);

  // eslint-disable-next-line react/prop-types
  const { setPage, setAuthenticated } = props;

  const getTitle = () => (
    <Typography variant="h4" sx={{ color: 'white' }}>User Registration</Typography>
  );

  const getMsg = () => (
    <Typography variant="string" sx={{ color: 'white', m: 1 }}>{msg}</Typography>
  );

  const getUserField = () => (
    <Box>
      <TextField
        value={user}
        variant="outlined"
        id="email"
        name="email"
        label="Email Address"
        type="email"
        spellCheck={false}
        onChange={(e) => setUser(e.target.value)}
        InputLabelProps={{ shrink: true, style: { color: 'white' } }}
        sx={{ ...style, m: 1, width: '300px' }}
        inputProps={{ style: { color: 'white' }, autoComplete: 'off' }}
      />
    </Box>
  );

  const getPasswordField = () => (
    <Box>
      <TextField
        name="pass"
        type="password"
        value={pass}
        label="Password"
        variant="outlined"
        spellCheck={false}
        onChange={(e) => setPass(e.target.value)}
        InputLabelProps={{ shrink: true, style: { color: 'white' } }}
        sx={{ ...style, m: 1, width: '300px' }}
        inputProps={{ style: { color: 'white' }, autoComplete: 'new-password' }}
      />
    </Box>
  );

  const doRegister = async () => {
    if (!user || !pass) {
      setMsg('Please enter a valid email address and password.');
      return;
    }

    const body = JSON.stringify({ user, pass });

    setSpinner(true);

    const createUserRsp = await fetch(
      '/api/public/create-user',
      { method: 'POST', body },
    );

    if (createUserRsp.status === 400) {
      const err = await createUserRsp.text();
      setMsg(err);
      setSpinner(false);
      return;
    }

    if (createUserRsp.status !== 200) {
      setMsg('Unknown error creating user.');
      setSpinner(false);
      return;
    }

    const Authorization = getAuthHeader(user, pass);

    const loginRsp = await fetch(
      '/api/public/login',
      { headers: { Authorization } },
    );

    if (loginRsp.status !== 200) {
      setMsg('User created, but subsequent login failed. Please login manually.');
      setSpinner(false);
      return;
    }

    const { token } = await loginRsp.json();
    Cookies.set('authToken', token);

    setMsg('Success, redirecting to account...');
    setSpinner(false);
    setAuthenticated(true);
    setPage(8);
  };

  const getRegisterButton = () => (
    <Button
      variant="contained"
      onClick={doRegister}
      sx={{
        backgroundColor: '#bb4420',
        borderColor: '#bb4420',
        color: 'white',
        ':hover': {
          bgcolor: '#99371a',
          color: 'white',
          borderColor: 'white',
        },
      }}
    >
      Register
    </Button>
  );

  if (spinner) {
    return renderSpinner();
  }

  return (
    <Box
      overflow="auto"
      sx={{
        color: 'white',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {getTitle()}
      {getMsg()}
      {getUserField()}
      {getPasswordField()}
      {getRegisterButton()}
    </Box>
  );
}

export default Register;
