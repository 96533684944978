/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const style = {
  m: 1,
  width: '100%',
  color: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#bb4220',
  },
  '&.Mui-focused': {
    borderColor: '#bb4220',
    color: '#bb4220',
  },
  '&:hover': {
    '&& fieldset': {
      borderColor: '#bb4220',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#bb4220',
    },
  },
  '.MuiSvgIcon-root ': {
    fill: 'white !important',
  },
};

const switchStyle = {
  mx: 2,
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
      '+.MuiSwitch-track': {
        backgroundColor: '#bb4220',
        opacity: 1.0,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
  },
};

const renderSpinner = () => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }}
  >
    <CircularProgress size="10vh" sx={{ color: '#bb4420' }} />
  </Box>
);

const getAuthHeader = (user, pass) => {
  const authHeaderString = `${user}:${pass}`;
  const encodedAuthString = btoa(authHeaderString);
  return `Basic ${encodedAuthString}`;
};

const getTimeUntil = (futureDate) => {
  const now = new Date();
  const diffInSeconds = Math.floor((futureDate - now) / 1000);

  if (diffInSeconds < 0) {
    return 'imminently';
  }

  const minutes = Math.floor((diffInSeconds / 60) % 60);
  const hours = Math.floor((diffInSeconds / (60 * 60)) % 24);
  const days = Math.floor(diffInSeconds / (60 * 60 * 24));

  if (days > 0) {
    return `in ${days} days`;
  }

  if (hours > 0) {
    return `in ${hours} hours`;
  }

  if (minutes > 0) {
    return `in ${minutes} minutes`;
  }

  return 'imminently';
};

export {
  style, switchStyle, renderSpinner, getAuthHeader, getTimeUntil,
};
