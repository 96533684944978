/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Typography, Button, Box, TextField, FormControlLabel, Switch, IconButton,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Cookies from 'js-cookie';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { style, switchStyle } from './utils';

export default function GuildAdministratorPanel(props) {
  const {
    affiliations, adminView, setRefreshTrick, userName,
  } = props;

  const count = affiliations.filter((aff) => aff.admin).length;

  const [addUserMsg, setAddUserMsg] = useState('');
  const [userToAdd, setUserToAdd] = useState('');
  const [userToAddUpload, setUserToAddUpload] = useState(true);
  const [userToAddPlayback, setUserToAddPlayback] = useState(true);

  const renderGuildAdminTable = (view) => {
    const rows = view.members.map((member) => ({
      userName: member.user,
      adminText: member.affiliation.admin ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />,
      playbackText: member.affiliation.playback ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />,
      uploadText: member.affiliation.upload ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />,
    }));

    const doRemoveUser = async (user, guild) => {
      const authToken = Cookies.get('authToken');
      const Authorization = `Bearer ${authToken}`;

      const toRemove = {
        user,
        guild,
      };

      const body = JSON.stringify(toRemove);

      const rsp = await fetch(
        '/api/private/deaffiliate-user',
        {
          headers: { Authorization },
          method: 'POST',
          body,
        },
      );

      if (rsp.status === 200) {
        setRefreshTrick((prev) => prev + 1);
      }
    };

    const renderRemoveUserButton = (user, guild) => {
      const disabled = userName === user;
      const color = disabled ? 'grey' : 'white';

      return (
        <IconButton disabled={userName === user}>
          <DeleteForeverIcon
            style={{ color }}
            onClick={() => doRemoveUser(user, guild)}
          />
        </IconButton>
      );
    };

    return (
      <TableContainer component={Paper} sx={{ backgroundColor: '#3B3B3B' }}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ '& .MuiTableCell-root': { borderBottom: '1px solid black' } }}>
              <TableCell sx={{ color: 'white' }}>{view.guild}</TableCell>
              <TableCell sx={{ color: 'white' }} align="right">Admin</TableCell>
              <TableCell sx={{ color: 'white' }} align="right">Playback</TableCell>
              <TableCell sx={{ color: 'white' }} align="right">Upload</TableCell>
              <TableCell sx={{ color: 'white' }} align="right">Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.guild}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.userName === userName ? `${userName} (You)` : row.userName}</TableCell>
                <TableCell sx={{ color: 'white' }} align="right">{row.adminText}</TableCell>
                <TableCell sx={{ color: 'white' }} align="right">{row.playbackText}</TableCell>
                <TableCell sx={{ color: 'white' }} align="right">{row.uploadText}</TableCell>
                <TableCell sx={{ color: 'white' }} align="right">{renderRemoveUserButton(row.userName, view.guild)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderAddUserField = (view) => {
    const addUser = async () => {
      if (userToAdd.length < 1) {
        setAddUserMsg('You must enter a username.');
        return;
      }

      const authToken = Cookies.get('authToken');
      const Authorization = `Bearer ${authToken}`;

      const toAdd = {
        user: userToAdd,
        guild: view.guild,
        playback: userToAddPlayback,
        upload: userToAddUpload,
      };

      const body = JSON.stringify(toAdd);

      const rsp = await fetch(
        '/api/private/affiliate-user',
        {
          headers: { Authorization },
          method: 'POST',
          body,
        },
      );

      if (rsp.status === 200) {
        setAddUserMsg('Successfully added user!');
        setRefreshTrick((prev) => prev + 1);
      } else if (rsp.status === 400) {
        const msg = await rsp.text();
        setAddUserMsg(msg);
      } else {
        setAddUserMsg('Failed to add user.');
      }
    };

    return (
      <Box
        overflow="auto"
        sx={{
          color: 'white',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          my: 1,
        }}
      >
        <TextField
          name="Username"
          value={userToAdd}
          label="Username"
          variant="outlined"
          spellCheck={false}
          onChange={(e) => setUserToAdd(e.target.value)}
          InputLabelProps={{ shrink: true, style: { color: 'white' } }}
          sx={{ ...style, my: 1, width: '250px' }}
          inputProps={{ style: { color: 'white' }, autoComplete: 'off' }}
        />
        <FormControlLabel
          control={(
            <Switch
              sx={switchStyle}
              checked={userToAddPlayback}
              onChange={(e) => {
                setUserToAddPlayback(e.target.checked);

                if (!e.target.checked) {
                  setUserToAddUpload(false);
                }
              }}
            />
        )}
          label="Playback"
          labelPlacement="top"
          sx={{
            color: 'white',
          }}
        />
        {userToAddPlayback
        && (
        <FormControlLabel
          control={(
            <Switch
              sx={switchStyle}
              checked={userToAddUpload}
              onChange={(e) => setUserToAddUpload(e.target.checked)}
            />
        )}
          label="Upload"
          labelPlacement="top"
          sx={{
            color: 'white',
          }}
        />
        )}
        <Button
          variant="contained"
          onClick={addUser}
          sx={{
            backgroundColor: '#bb4420',
            borderColor: '#bb4420',
            m: 1,
            color: 'white',
            ':hover': {
              bgcolor: '#99371a',
              color: 'white',
              borderColor: 'white',
            },
          }}
        >
          Add User
        </Button>
        <Typography sx={{ mx: 2 }}>{addUserMsg}</Typography>
      </Box>
    );
  };

  const renderGuildAdmin = (view) => (
    <>
      {renderGuildAdminTable(view)}
      {renderAddUserField(view)}
    </>
  );

  if (count === 0) {
    return (
      <Typography sx={{ color: 'grey' }}>
        You are not an administrator of any guilds.
      </Typography>
    );
  }

  const header = `You are the administrator of ${affiliations.length} guild(s).`;

  return (
    <>
      <Typography sx={{ color: 'grey', mb: 1 }}>{header}</Typography>
      {adminView.map(renderGuildAdmin)}
    </>
  );
}
