import React, { useState } from 'react';
import {
  Box, TextField, Typography, Button,
} from '@mui/material';
import { style, renderSpinner } from './utils';

function ForgotPassword() {
  const [user, setUser] = useState('');
  const [msg, setMsg] = useState('');
  const [done, setDone] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const sendResetEmail = async () => {
    setSpinner(true);

    if (!user) {
      setMsg('Please enter a user');
      setSpinner(false);
      return;
    }

    const rsp = await fetch(`/api/forgot-password/${user}`);
    setSpinner(false);

    if (rsp.ok) {
      setMsg('Password reset link sent, please check your email. If it does not arrive, check your junk folder.');
      setDone(true);
    } else {
      const reason = await rsp.text();
      setMsg(reason);
    }
  };

  const getResetButton = () => (
    <Button
      variant="contained"
      onClick={sendResetEmail}
      sx={{
        m: 1,
        backgroundColor: '#bb4420',
        borderColor: '#bb4420',
        color: 'white',
        ':hover': {
          bgcolor: '#99371a',
          color: 'white',
          borderColor: 'white',
        },
      }}
    >
      Reset
    </Button>
  );

  const getUserField = () => (
    <Box>
      <TextField
        name="user"
        value={user}
        label="Email / User"
        variant="outlined"
        spellCheck={false}
        onChange={(e) => setUser(e.target.value)}
        InputLabelProps={{ shrink: true, style: { color: 'white' } }}
        sx={{ ...style, m: 1, width: '300px' }}
        inputProps={{ style: { color: 'white' } }}
      />
    </Box>
  );

  const getTitle = () => (
    <Typography variant="h4" sx={{ color: 'white' }}>Forgot Password</Typography>
  );

  const getMsg = () => (
    <Typography variant="string" sx={{ color: 'white', m: 1 }}>{msg}</Typography>
  );

  if (spinner) {
    return renderSpinner();
  }

  return (
    <Box
      overflow="auto"
      sx={{
        color: 'white',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {getTitle()}
      {getMsg()}
      {!done && getUserField()}
      {!done && getResetButton()}
    </Box>
  );
}

export default ForgotPassword;
