import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import dungeon from './images/dungeon.png';
import scene from './images/scene.png';
import settings from './images/settings.png';
import cloud from './images/cloud.png';
import clipping from './images/clipping.png';
import search from './images/search.png';
import link from './images/link.png';
import disk from './images/disk.png';
import download from './images/download-pov.png';
import proSettings from './images/pro-settings.png';
import tag from './images/tag.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Automatically record and review your World of Warcraft gameplay.',
    imgPath: dungeon,
  },
  {
    label: 'Quickly find videos using the search bar.',
    imgPath: search,
  },
  {
    label: 'Easily clip key moments with just a few clicks.',
    imgPath: clipping,
  },
  {
    label: 'Tag a recording to remind future you why it is memorable.',
    imgPath: tag,
  },
  {
    label: 'Efficient and highly configurable recording, powered by OBS.',
    imgPath: scene,
  },
  {
    label: 'Fine-grained customization of the content that is recorded.',
    imgPath: settings,
  },
  {
    label: 'Disk monitoring capabilities track usage and avoid filling up your PC.',
    imgPath: disk,
  },
  {
    label: 'With a Warcraft Recorder Pro subscription, store your videos in the cloud and view your teammates recordings on demand.',
    imgPath: cloud,
  },
  {
    label: 'Fine-grained control over what gets uploaded and what stays local.',
    imgPath: proSettings,
  },
  {
    label: 'Generate shareable links to cloud hosted videos that anyone can view.',
    imgPath: link,
  },
  {
    label: 'Download your teammates videos to your computer with a single click.',
    imgPath: download,
  },
];

function About() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval={5000}
        autoplay
      >
        {images.map((step) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              my: 2,
            }}
            key={step.label}
          >
            <Box
              component="img"
              sx={{
                maxHeight: '60%',
                maxWidth: '60%',
                objectFit: 'contain',
                border: '1px solid #474747',
                boxShadow: 3,
              }}
              src={step.imgPath}
              alt={step.label}
            />
          </Box>
        ))}
      </AutoPlaySwipeableViews>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'center',
          height: 40,
          bgcolor: 'transparent',
        }}
      >
        <Typography sx={{ color: 'white' }}>{images[activeStep].label}</Typography>
      </Paper>
      <MobileStepper
        sx={{
          bgcolor: 'transparent',
          color: '#bb4420',
          '& .MuiMobileStepper-dot': {
            backgroundColor: 'white',
          },
          '& .MuiMobileStepper-dotActive': {
            backgroundColor: '#bb4420',
          },
        }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={(
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight sx={{ color: '#bb4420' }} />
          </Button>
        )}
        backButton={(
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft sx={{ color: '#bb4420' }} />
          </Button>
        )}
      />
    </Box>
  );
}

export default About;
